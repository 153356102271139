import React from "react";
import { InputBase, makeStyles } from "@material-ui/core";

export function TextInput({
  type = "text",
  error = null,
  name,
  placeholder,
  style,
  search = false,
  ...otherProps
}) {
  const classes = useStyles();

  return (
    <InputBase
      name={name}
      id={name}
      type={"text"}
      placeholder={placeholder}
      variant="outlined"
      className={classes.input}
      style={{ border: error ? "1px solid red" : "1px solid #FFF", ...style }}
      {...otherProps}
    />
  );
}
const useStyles = makeStyles({
  input: {
    width: "100%",
    marginTop: "0.5em",
    padding: "10px",

    borderRadius: "10px",
    color: "#FFF",
  },
  passwordIcon: {
    color: "#969696",
    height: 18,
    width: 18,
  },
  searchIcon: {
    color: "white",
    height: 20,
    width: 20,
  },
});
