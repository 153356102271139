import React, { useState, useEffect, useRef } from "react";
import { Backdrop, Fade, Modal } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { API } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";

import { Logo } from "../../../components";
import {
  DesktopView,
  SubscribeModal,
  Tabs,
  WatchTrailer,
  MobileView,
} from "./components";
import { useStyles } from "./styles";
import { validateEmail } from "../../../lib/utils";
import { showAlert } from "../../../redux/modules/alertHandler";
import { ALERT_TYPES, VIDEOS } from "../../../lib/constant";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Home() {
  const classes = useStyles();
  const query = useQuery();
  const [videoId, setVideoId] = useState(null);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isSubscribeModalVisible, setIsSubscribeModalVisible] = useState(false);
  const history = useHistory();
  const { ip, countryCode } = useSelector((state) => state.location);
  const mainRef = useRef();

  const dispatch = useDispatch();

  useEffect(() => {
    setVideoId(query.get("videoId"));
  }, [query, setVideoId]);

  const handleEmailChange = ({ target: { value } }) => setEmail(value);

  useEffect(() => {
    setIsValidEmail(validateEmail(email.trim()));
  }, [email, setIsValidEmail]);

  useEffect(() => {
    if (mainRef) {
      setTimeout(() => {
        window.scrollTo({ behavior: "smooth", top: 200 });
      }, 1000);
    }
  }, [mainRef]);

  useEffect(() => {
    setTimeout(openPlayer, 3000);
  }, []);

  const openPlayer = () => {
    setIsSubscribeModalVisible(false);
    history.push(`?videoId=${VIDEOS[0].id}`);
  };

  const handleClose = () => {
    setVideoId(null);
    history.replace("/");
  };

  const openSubscribeModal = () => setIsSubscribeModalVisible(true);
  const closeSubscribeModal = () => setIsSubscribeModalVisible(false);

  const subscribe = async () => {
    setLoading(true);
    try {
      const params = {
        body: {
          email,
          source: "Ishrat: Made in China",
          ip_address: ip,
          country_code: countryCode,
        },
        headers: {
          "Content-Type": "application/json",
        },
      };

      const response = await API.post("users", "/add", params);
      setLoading(false);
      setEmail("");
      dispatch(
        showAlert({
          type: ALERT_TYPES.SUCCESS,
          message: response.message,
          isVisible: true,
        })
      );
    } catch (err) {
      setLoading(false);
      dispatch(
        showAlert({
          type: ALERT_TYPES.ERROR,
          message: "Something went wrong. Please try again",
          isVisible: true,
        })
      );
    }
  };

  return (
    <div
      ref={mainRef}
      id="mainContainer"
      style={{ flex: 1, display: "flex", flexDirection: "column" }}
    >
      <div className={classes.homeMainContainer}>
        {((isMobile && !videoId) || !isMobile) && (
          <div className={classes.logoContainer}>
            <Logo />
          </div>
        )}
        {isMobile ? (
          <MobileView />
        ) : (
          <DesktopView
            isValidEmail={isValidEmail}
            openPlayer={openPlayer}
            handleEmailChange={handleEmailChange}
            email={email}
            subscribe={subscribe}
            loading={loading}
          />
        )}

        <Modal
          open={videoId}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className={classes.trailerModal}
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 10000,
          }}
          closeAfterTransition
        >
          <Fade open={videoId}>
            <WatchTrailer videoId={videoId} />
          </Fade>
        </Modal>

        <Modal
          open={isSubscribeModalVisible}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className={classes.trailerModal}
          style={{ outline: "none" }}
        >
          <SubscribeModal
            handleEmailChange={handleEmailChange}
            isValidEmail={isValidEmail}
            email={email}
            closeSubscribeModal={closeSubscribeModal}
            subscribe={subscribe}
            loading={loading}
          />
        </Modal>
      </div>

      {isMobile && (
        <Tabs openPlayer={openPlayer} openSubscribeModal={openSubscribeModal} />
      )}
    </div>
  );
}

export default Home;
