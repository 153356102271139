import axios from "axios";

// constants
const SET_LOCATION = "SET_LOCATION";

// thunks

export const fetchLocation = () => async (dispatch, getState) => {
  try {
    // this wasn't working any more
    // need subscription
    // https://ipapi.co/json/ throwing cors issue
    const response = await axios.get("https://json.geoiplookup.io/");
    dispatch({
      type: SET_LOCATION,
      payload: {
        ...response.data,
        countryCode: response.data.country_code,
        ip: response.data.ip,
      },
    });
  } catch (error) {
    throw error;
  }
};

// reducer
const initialState = { countryCode: null, ip: null };

const reducer = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case SET_LOCATION: {
      return {
        ...state,
        ...payload,
      };
    }
    default:
      return state;
  }
};

export default reducer;
