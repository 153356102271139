import React from "react";
import { makeStyles } from "@material-ui/core";

import { Footer } from "../../../../components";

export const MobileView = () => {
  const classes = useStyles();
  return (
    <div className={classes.tabletView}>
      <Footer />
    </div>
  );
};

const useStyles = makeStyles(() => ({
  tabletView: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    paddingBottom: 10,
  },
}));
