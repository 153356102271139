import { combineReducers } from "redux";

import location from "./modules/location";
import alertHandler from "./modules/alertHandler";

const reducers = combineReducers({
  location,
  alertHandler,
});

const rootReducer = (state, action) => {
  // Clear persistant storage on logout
  // Dispatch RESET_APP on logout
  if (action.type === "RESET_APP") {
    state = {};
  }

  return reducers(state, action);
};

export default rootReducer;
