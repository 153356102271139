import React, { useEffect, useState } from "react";
import { VIDEOS } from "../../../../lib/constant";
import { isMobileOnly, isTablet } from "react-device-detect";
import { MobileTrailerView, TabletTrailerView, DesktopTrailerView } from ".";

export const WatchTrailer = ({ videoId }) => {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    const data = VIDEOS.filter((item) => item.id !== videoId);
    setVideos(data);
  }, [videoId, setVideos]);

  if (isMobileOnly) {
    return <MobileTrailerView videoId={videoId} videos={videos} />;
  } else if (isTablet) {
    return <TabletTrailerView videoId={videoId} videos={videos} />;
  } else return <DesktopTrailerView videoId={videoId} videos={videos} />;
};
