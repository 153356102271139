import React from "react";
import { Button, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import {
  PrimaryButton,
  PrimaryButtonHover,
  ButtonText,
  LightSilver,
} from "../../styles/colors";

export const ButtonComponent = ({
  disabled,
  onClickHandler,
  title,
  width,
  id = "button",
  hoverBackgroundColor = PrimaryButtonHover,
  backgroundColor = PrimaryButton,
  titleColor = ButtonText,
  hoverTitleColor = "white",
  borderColor = PrimaryButton,
  borderTopRightRadius = 4,
  borderTopLeftRadius = 4,
  borderBottomLeftRadius = 4,
  borderBottomRightRadius = 4,
  backdropFilter = "none",
  height = 40,
  icon = null,
  loading = false,
  hoverBorderColor = null,
  style = {},
}) => {
  const useStyles = makeStyles((theme) => ({
    buttonBackground: {
      width: width,
      color: titleColor,
      backgroundColor: loading ? hoverBackgroundColor : backgroundColor,
      border: `1px solid ${loading ? hoverBackgroundColor : borderColor}`,
      borderTopRightRadius,
      borderTopLeftRadius,
      borderBottomLeftRadius,
      borderBottomRightRadius,
      height: height,
      backdropFilter: backdropFilter,
      "&:hover": {
        backgroundColor: hoverBackgroundColor,
        border: `1px solid ${
          hoverBorderColor ? hoverBorderColor : hoverBackgroundColor
        }`,
        color: hoverTitleColor,
      },
      "&:disabled": {
        backgroundColor: "rgba(255,255,255,0.5)",
        color: LightSilver,
      },
    },
    circularProgress: {
      color: "white",
    },
  }));

  const classes = useStyles();

  return (
    <Button
      id={id}
      disabled={disabled}
      style={{ ...style }}
      className={classes.buttonBackground}
      onClick={() => !loading && onClickHandler()}
      variant="outlined"
    >
      {loading ? (
        <CircularProgress size={20} className={classes.circularProgress} />
      ) : (
        <span className={"button-title"}>
          {icon}
          {title}
        </span>
      )}
    </Button>
  );
};
