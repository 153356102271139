import React from "react";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";

import { ButtonComponent, Footer } from "../../../../components";
import { Form } from ".";
import { makeStyles, Grid } from "@material-ui/core";

export const DesktopView = ({
  openPlayer,
  handleEmailChange,
  email,
  isValidEmail,
  subscribe,
  loading,
}) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.gridContainer}>
      <Grid item lg={8} xl={9} md={7} sm={12} xs={12}></Grid>
      <Grid item lg={4} xl={3} md={5} sm={12} xs={12}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <div className={classes.formContainer}>
              <div style={{ margin: "30px 15px" }}>
                <Form
                  handleEmailChange={handleEmailChange}
                  email={email}
                  isValidEmail={isValidEmail}
                  subscribe={subscribe}
                  loading={loading}
                />
              </div>
            </div>
            <div className={classes.trailerButtonContainer}>
              <ButtonComponent
                id={"watch-trailer"}
                title="Watch trailers"
                backgroundColor={"rgba(94, 207, 99, 0.7)"}
                borderColor={"transparent"}
                hoverBackgroundColor={"rgba(94, 207, 99, 0.7)"}
                hoverBorderColor={"rgba(94, 207, 99, 0.7)"}
                backdropFilter={"blur(44px)"}
                onClickHandler={openPlayer}
                width={"65%"}
                height={40}
                icon={<PlayArrowIcon style={{ paddingRight: 5 }} />}
              />
            </div>
            <Footer />
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    color: "white",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
    paddingBottom: 100,
    alignItems: "center",
    flexDirection: "column",
  },
  formContainer: {
    width: "65%",
    background: "rgba(20, 19, 28, 0.6)",
    backdropFilter: "blur(10px)",
    borderRadius: "10px",
    [theme.breakpoints.down("xs")]: {
      width: "80%",
    },
  },
  trailerButtonContainer: {
    paddingBottom: "15px",
    marginTop: 30,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    // [theme.breakpoints.down("md")]: {
    //   justifyContent: "flex-end",
    //   // paddingRight:15
    // },
  },
  gridContainer: {
    flex: 1,
  },
}));
