import { makeStyles } from "@material-ui/core";
import backgroundImage from "../../../assets/images/poster.jpg";
export const useStyles = makeStyles((theme) => ({
  homeMainContainer: {
    // minHeight: "100vh",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    fontSize: "calc(10px + 2vmin)",
    backgroundImage: `url(${backgroundImage})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    "-webkit-background-size": "cover",
    "-moz-background-size": "cover",
    "-o-background-size": "cover",
    backgroundSize: "contain",
    position: "relative",
  },
  check: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  logoContainer: {
    position: "absolute",
    top: 10,
    left: 20,
    zIndex: 100000000000,
    // [theme.breakpoints.down("sm")]: {
    //   display: "none",
    // },
    // [theme.breakpoints.down("md")]: {
    //   display: "none",
    // },
  },
  ishratLogo: {
    width: "30vmin",
  },

  inputContainer: {
    padding: "0px 5px",
  },
  input: {
    color: "white",
    border: `1px solid white`,
    borderRadius: 4,
    width: "100%",
    paddingLeft: 12,
    paddingRight: 12,
    marginBottom: 10,
    marginTop: "0.8em",
  },

  title: {
    color: "white",
    fontSize: 14,
    fontWeight: "600",
    textTransform: "uppercase",
    textAlign: "center",
  },
  footerContainer: {
    padding: "10px 0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  trailerModal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "rgba(0, 0, 0, 0.1)",
    backdropFilter: "blur(10px)",
    outline: "none",
  },
}));
