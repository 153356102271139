// constants
const SHOW_ALERT = "SHOW_ALERT";
const HIDE_ALERT = "HIDE_ALERT";

export const showAlert = (payload) => {
  return {
    type: SHOW_ALERT,
    payload,
  };
};

export const hideAlert = () => {
  return {
    type: HIDE_ALERT,
  };
};

// reducer
const initialState = {
  message: "",
  type: "",
  isVisible: false,
};

const reducer = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case SHOW_ALERT: {
      return {
        ...payload,
      };
    }
    case HIDE_ALERT: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

export default reducer;
