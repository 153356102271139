import { FooterIcons } from "../../lib/constant";
import { makeStyles } from "@material-ui/core";

export const Footer = () => {
  const classes = useStyles();
  return (
    <div className={classes.footerContainer}>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
      />
      {FooterIcons.map((item) => {
        return (
          <div className={classes.footerIconContainer}>
            <a
              key={item.id}
              href={item.navigateTo}
              target="_blank"
              className={item.icon}
              rel="noreferrer"
            />
          </div>
        );
      })}
      <div className={classes.footerHashTag}>#ISHRATMADEINCHINA</div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  footerContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  footerIconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "25px",
    fontSize: "15px",
  },
  footerHashTag: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "13px",
    color: "#ffffff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px 15px",
    marginTop: 15,

    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
}));
