import React from "react";
import { IconButton, makeStyles } from "@material-ui/core";

import { Close } from "@material-ui/icons";
import { Form } from ".";

export const SubscribeModal = ({
  closeSubscribeModal,
  handleEmailChange,
  email,
  isValidEmail,
  subscribe,
  loading,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.mainContainer}>
      <div style={{ maxWidth: 450, width: "100%" }}>
        <Form
          handleEmailChange={handleEmailChange}
          email={email}
          isValidEmail={isValidEmail}
          subscribe={subscribe}
          loading={loading}
        />
      </div>
      <div className={classes.closeButtonContainer}>
        <IconButton onClick={closeSubscribeModal}>
          <Close className={classes.closeIcon} />
        </IconButton>
      </div>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  mainContainer: {
    position: "relative",
    flex: 1,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: 50,
  },
  closeButtonContainer: {
    position: "absolute",
    top: 50,
    right: 0,
  },
  closeIcon: {
    color: "white",
  },
}));
