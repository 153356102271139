import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import "../theme/Common.css";
// SCREENS
import UnAuthenticate from "../pages/unauthenticate";
import { Alert } from "../components";

function Routes() {
  return (
    <Router>
      <div
        style={{
          height: "100%",
          minHeight: "100vh",
          display: "flex",
        }}
      >
        <Switch>
          <Route path={"/"}>
            <UnAuthenticate />
          </Route>
        </Switch>

        <Alert />
      </div>
    </Router>
  );
}

export default Routes;
