import React, { useEffect } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import { useSelector, useDispatch } from "react-redux";

import { useAlertStyles } from "./styles";
import { ALERT_TYPES } from "../../lib/constant";
import { hideAlert } from "../../redux/modules/alertHandler";
import { Slide, Typography } from "@material-ui/core";

function TransitionDown(props) {
  return <Slide {...props} direction="down" />;
}

export const Alert = () => {
  const classes = useAlertStyles();
  const { type, isVisible, message } = useSelector(
    (state) => state.alertHandler
  );
  const dispatch = useDispatch();

  const handleClose = () => dispatch(hideAlert());

  useEffect(() => {
    if (isVisible) {
      setTimeout(() => {
        dispatch(hideAlert());
      }, 4000);
    }
  }, [isVisible, dispatch]);

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={isVisible}
      onClose={handleClose}
      message={
        <Typography className={classes.alertContent}>{message}</Typography>
      }
      TransitionComponent={TransitionDown}
      key={"download-error"}
      ContentProps={{
        classes: {
          root: `${classes.root} ${
            type === ALERT_TYPES.ERROR
              ? classes.error
              : type === ALERT_TYPES.SUCCESS
              ? classes.success
              : ""
          }`,
        },
      }}
    />
  );
};
