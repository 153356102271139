import { video } from "../assets";

export const FooterIcons = [
  {
    id: "facebook",
    icon: "fa fa-facebook footer-icon",
    navigateTo: "https://www.facebook.com/IshratTheFilm/",
  },
  {
    id: "twitter",
    icon: "fa fa-twitter footer-icon",
    navigateTo: "https://twitter.com/IshratTheFilm",
  },
  {
    id: "instagram",
    icon: "fa fa-instagram footer-icon",
    navigateTo: "https://www.instagram.com/ishratthefilm/",
  },
  {
    id: "youtube",
    icon: "fa fa-youtube-play footer-icon",
    navigateTo: "https://www.youtube.com/channel/UCvpDDjtbWRVw_-Y2psoQ4Xg",
  },
];

export const VIDEOS = [
  {
    id: "aG0Q669OztA",
    image: video,
    title: "First Look",
  },
  {
    id: "BQFhbgUPfZ0",
    image: video,
    title: "Official Teaser",
  },
];

export const ALERT_TYPES = {
  ERROR: "ERROR",
  SUCCESS: "SUCCESS",
};
