import React from "react";
import ReactDOM from "react-dom";
import Amplify from "aws-amplify";
import { createBrowserHistory } from "history";

import "./index.css";
import App from "./app/App";
import reportWebVitals from "./reportWebVitals";
import awsconfig from "./aws-exports";

require("dotenv").config();

Amplify.configure(awsconfig);

const history = createBrowserHistory();

const app = document.getElementById("root");

// S3 bucket hosting won't load page when we refresh the page.
// https://via.studio/journal/hosting-a-reactjs-app-with-routing-on-aws-s3
if (app) {
  const path = (/#!(\/.*)$/.exec(history.location.hash) || [])[1];

  if (path) {
    history.replace(path);
  }

  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    app
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
