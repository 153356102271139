import React from "react";
import { logo } from "../../assets";
import { makeStyles } from "@material-ui/core";

export const Logo = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <a target="_blank" href="https://www.mintrio.com/">
        <img className={classes.logoImage} src={logo} role="presentation" />
      </a>
      <p className={classes.title}>MINTRIO</p>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  logoImage: {
    width: "80%",
  },
  title: {
    color: "white",
    textTransform: "uppercase",
    fontSize: "14px",
    fontWeight: "700",
    padding: "0px 15px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}));
