import React from "react";
import { Typography, makeStyles } from "@material-ui/core";

import { ButtonComponent, TextInput } from "../../../../components";

export const Form = ({
  handleEmailChange,
  email,
  isValidEmail,
  subscribe,
  loading,
}) => {
  const classes = useStyles();
  return (
    <>
      <Typography className={classes.title}>Subscribe for updates</Typography>
      <TextInput
        name="email"
        placeholder="Email Address"
        value={email}
        onChange={handleEmailChange}
        error={!isValidEmail && email !== ""}
        className={classes.input}
      />

      <ButtonComponent
        disabled={!isValidEmail || email === ""}
        title="Subscribe"
        backgroundColor={"rgba(94, 207, 99, 0.7)"}
        borderColor={"transparent"}
        hoverBackgroundColor={"rgba(94, 207, 99, 0.7);"}
        hoverBorderColor={"rgba(94, 207, 99, 0.7);"}
        backdropFilter={"blur(44px)"}
        onClickHandler={subscribe}
        loading={loading}
        width={"100%"}
        height={35}
        style={{ fontSize: 12, marginTop: "1em" }}
      />
    </>
  );
};

const useStyles = makeStyles(() => ({
  mainContainer: {
    position: "relative",
    flex: 1,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: 50,
  },
  title: {
    color: "white",
    fontSize: 14,
    fontWeight: "600",
    textTransform: "uppercase",
    textAlign: "center",
  },
  input: {
    color: "white",
    borderRadius: 4,
    width: "100%",
    paddingLeft: 12,
    paddingRight: 12,
    marginBottom: 10,
    marginTop: "0.8em",
  },
}));
