import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";

import Home from "./Home";
import { PageNotFound } from "../pageNotFound";
import { makeStyles } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { fetchLocation } from "../../redux/modules/location";

const UnAuthenticate = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLocation());
  });
  return (
    <div className={classes.pageContainer}>
      <Switch>
        <Route exact path={"/"}>
          <Home />
        </Route>
        <Route path={"/*"}>
          <PageNotFound />
        </Route>
      </Switch>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  pageContainer: {
    overflow: "hidden",
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
}));

export default UnAuthenticate;
