import {
  isMobileOnly,
  isTablet,
  isDesktop,
  isSmartTV,
} from "react-device-detect";
import { Auth } from "aws-amplify";

export const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.toLowerCase());
};

export const validatePhoneNumber = (phoneNumber) => {
  var phoneno = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  return phoneNumber.match(phoneno);
};

export const detectDeviceType = () => {
  if (isMobileOnly) return "Mobile";
  if (isTablet) return "Tablet";
  if (isSmartTV) return "TV";
  if (isDesktop) return "Desktop";
};

export const getToken = async () => {
  return await (await Auth.currentSession()).getIdToken().getJwtToken();
};
