import {
  Typography,
  makeStyles,
  IconButton,
  useMediaQuery,
} from "@material-ui/core";
import { PlayArrow } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import React from "react";
import { VIDEOS } from "../../../../lib/constant";

export const DesktopTrailerView = ({ videoId, videos }) => {
  const classes = useStyles();
  const heightMatch = useMediaQuery("(min-height:700px)");
  const history = useHistory();

  return (
    <div
      className={classes.trailerContainer}
      style={{
        height: heightMatch
          ? videos.length === 0
            ? 490
            : 660
          : videos.length === 0
          ? 380
          : 500,
      }}
    >
      <div
        className={classes.videoPlayer}
        style={{ height: heightMatch ? 490 : 380 }}
      >
        <iframe
          width="100%"
          height="100%"
          src={`https://www.youtube.com/embed/${videoId}?autoplay=1&rel=0`}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen="allowfullscreen"
          mozallowfullscreen="mozallowfullscreen"
          msallowfullscreen="msallowfullscreen"
          oallowfullscreen="oallowfullscreen"
          webkitallowfullscreen="webkitallowfullscreen"
        ></iframe>
      </div>

      <div
        className={classes.videosList}
        style={{ height: heightMatch ? 175 : 120 }}
      >
        {videos.length > 0 &&
          videos.map(({ id, title, image }) => {
            return (
              <div key={id} className={classes.imgContainer}>
                <img
                  height={heightMatch ? 120 : 80}
                  className={classes.img}
                  src={image}
                />
                <div>
                  <Typography
                    style={{
                      color: id === videoId ? "gray" : "white",
                      fontSize: 12,
                      fontWeight: "600",
                    }}
                  >
                    {title}
                  </Typography>
                </div>
                <div className={classes.playButtonContainer}>
                  <IconButton
                    size="medium"
                    onClick={() => history.push(`/?videoId=${id}`)}
                    className={classes.playButton}
                  >
                    <PlayArrow className={classes.playIcon} />
                  </IconButton>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  trailerContainer: {
    width: "65%",
  },
  videoPlayer: {
    width: "100%",
    backgroundColor: "black",
  },
  playButtonContainer: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 3,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  playButton: {
    height: 40,
    width: 40,
    background: "rgba(128,128,128, 0.5)",
    backdropFilter: "blur(10px)",
  },
  playIcon: {
    color: "white",
    fontSize: 30,
  },
  videosList: {
    width: "100%",
    overflowY: "hidden",
    marginTop: 10,
    display: "flex",
    justifyContent: VIDEOS.length > 3 ? "flex-start" : "center",
    borderRadius: 4,
    overflow: "auto",
  },
  imgContainer: {
    margin: "20px 10px",
    position: "relative",
  },
  img: {
    borderRadius: 2,
  },
}));
