import React from "react";
import { makeStyles } from "@material-ui/core";

export const Tabs = ({ openPlayer, openSubscribeModal }) => {
  const classes = useStyles();
  return (
    <div style={{ backgroundColor: "black" }}>
      <div className={classes.tabs}>
        <div
          className={classes.tabItem}
          onClick={openPlayer}
          style={{ borderTopLeftRadius: 5 }}
        >
          Watch Trailers
        </div>
        <div
          className={classes.tabItem}
          onClick={openSubscribeModal}
          style={{ borderTopRightRadius: 5 }}
        >
          Subscribe
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  tabs: {
    height: 40,
    display: "flex",
    borderTop: "1px solid gray",
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  tabItem: {
    textTransform: "uppercase",
    borderLeft: "1px solid gray",
    flex: 1,
    height: 40,
    display: "flex",
    backgroundColor: "rgb(63,26,16)",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    color: "white",
    fontWeight: "500",
    fontSize: 14,
    "&:active": {
      backgroundColor: "rgb(29,11,5)",
      color: "white",
    },
  },
}));
