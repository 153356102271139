import { IconButton, makeStyles, Typography } from "@material-ui/core";
import { Close, PlayArrow } from "@material-ui/icons";
import React from "react";
import { useHistory } from "react-router-dom";
import { VIDEOS } from "../../../../lib/constant";

export const TabletTrailerView = ({ videoId, videos }) => {
  const classes = useStyles();
  const history = useHistory();

  const close = () => history.replace("/");

  return (
    <div className={classes.mainContainer}>
      <div
        className={
          videos.length === 0
            ? classes.singleVideoHeaderContainer
            : classes.headerContainer
        }
      >
        <IconButton onClick={close}>
          <Close className={classes.closeIcon} />
        </IconButton>
      </div>
      <div className={classes.playerContainer}>
        <iframe
          width="100%"
          height="100%"
          src={`https://www.youtube.com/embed/${videoId}?autoplay=1&rel=0`}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen="allowfullscreen"
          mozallowfullscreen="mozallowfullscreen"
          msallowfullscreen="msallowfullscreen"
          oallowfullscreen="oallowfullscreen"
          webkitallowfullscreen="webkitallowfullscreen"
        ></iframe>
      </div>
      {videos.length > 0 && (
        <div style={{ flex: 0.6, overflow: "auto" }}>
          {videos.map(({ id, title, image }) => (
            <div
              key={id}
              style={{
                borderRadius: 5,
                height: 250,
                width: "41.5%",
                margin: "3.9%",
                float: "left",
                backgroundImage: `url(${image})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                "-webkit-background-size": "cover",
                "-moz-background-size": "cover",
                "-o-background-size": "cover",
                backgroundSize: "contain",
                position: "relative",
              }}
            >
              <div className={classes.titleContainer}>
                <Typography
                  style={{
                    color: id === videoId ? "gray" : "white",
                    fontSize: 18,
                    fontWeight: "600",
                  }}
                >
                  {title}
                </Typography>
              </div>
              <div className={classes.playIconContainer}>
                <IconButton
                  size="medium"
                  onClick={() => history.push(`/?videoId=${id}`)}
                  className={classes.playButton}
                >
                  <PlayArrow className={classes.playIcon} />
                </IconButton>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles(() => ({
  mainContainer: {
    flex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    flexDirection: "column",
    justifyContent: VIDEOS.length > 1 ? "flex-start" : "center",
  },
  headerContainer: {
    height: 45,
    backgroundColor: "black",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  singleVideoHeaderContainer: {
    height: 45,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: "black",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  closeIcon: {
    color: "white",
  },
  playIconContainer: {
    color: "white",
    width: "100%",
    height: "100%",

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  playerContainer: {
    flex: 0.4,
    backgroundColor: "black",
  },
  playButton: {
    height: 60,
    width: 60,
    background: "rgba(128,128,128, 0.5)",
    backdropFilter: "blur(10px)",
  },
  titleContainer: {
    position: "absolute",
    bottom: -28,
    left: 0,
    right: 0,
  },
  playIcon: {
    color: "white",
    fontSize: 40,
  },
}));
